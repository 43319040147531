// extracted by mini-css-extract-plugin
export var regularFont = "sharedStyles-module--regularFont--KiMY-";
export var darkerFont = "sharedStyles-module--darkerFont--31PI2";
export var coloredFont = "sharedStyles-module--coloredFont--2iIFQ";
export var coloredFontCapitalize = "sharedStyles-module--coloredFontCapitalize--sTw4o";
export var gridContainer = "sharedStyles-module--gridContainer--2ftjb";
export var gridContainerSpaced = "sharedStyles-module--gridContainerSpaced--24TI4";
export var introText = "sharedStyles-module--introText--jH9o_";
export var center = "sharedStyles-module--center--2CTNL";
export var textContainer = "sharedStyles-module--textContainer--hli9c";
export var projectText = "sharedStyles-module--projectText--QGGHp";
export var projectHeader = "sharedStyles-module--projectHeader--2ZFLz";
export var container = "sharedStyles-module--container--2N-Is";
export var mobileContainer = "sharedStyles-module--mobileContainer--1IQ_W";
export var demo = "sharedStyles-module--demo--19OaO";
export var animated = "sharedStyles-module--animated--HE3pv";
export var highlight = "sharedStyles-module--highlight--zk7WA";
export var hyperlink = "sharedStyles-module--hyperlink--1hyXm";
export var divider = "sharedStyles-module--divider--3GKis";
export var verticalTimeline = "sharedStyles-module--vertical-timeline--xiksK";
export var verticalTimelineCustomLine = "sharedStyles-module--vertical-timeline-custom-line--3PBK6";
export var chipContainer = "sharedStyles-module--chipContainer--bveeq";
export var timelineContainer = "sharedStyles-module--timelineContainer--2AbNc";
export var cardImage = "sharedStyles-module--cardImage--xm7dw";
export var bottomRight = "sharedStyles-module--bottom-right--G04Nn";
export var resumeContainer = "sharedStyles-module--resumeContainer--3e4yG";